<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-select
            placeholder="请选择门店"
            class="search-input"
            v-model="searchObj.storeId"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in storeList"
              :key="index"
              :label="item.storeName"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-input
            placeholder="请输入商户名称"
            class="search-input ml-10"
            v-model="searchObj.merchantName"
          ></el-input>
          <el-input
            placeholder="请输入员工姓名"
            class="search-input ml-10"
            v-model="searchObj.name"
          ></el-input>
          <el-input
            placeholder="请输入手机号"
            class="search-input ml-10"
            v-model="searchObj.phone"
          ></el-input>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="ml-10"
            @click="search"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh" class="ml-10" @click="reset"
            >重置</el-button
          >
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 操作栏 -->
          <div class="flx-row ali-c js-btw">
            <div class="flx-row ali-c">
              <el-button class="ml-15" type="primary" @click="openDialog('add')"
                >添加商户</el-button
              >
            </div>
          </div>
          <!-- 表格 -->
          <el-table
            class="mt-24 member-table"
            :data="tableData"
            @selection-change="tableSelect"
          >
            <!-- <el-table-column type="selection"></el-table-column> -->

            <el-table-column label="核销员工" prop="name"></el-table-column>
            <el-table-column label="登录手机号" prop="phone"></el-table-column>
            <el-table-column label="核销人员类型" prop="type">
              <!-- 员工类型 1 商户核销人 2 门店核销人 -->
              <template slot-scope="scope">
                <span v-if="scope.row.type == 1">
                  商户核销人
                </span>
                <span v-else-if="scope.row.type == 2">
                  门店核销人
                </span>
                <span v-else>
                  暂无
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="所属门店"
              prop="storeName"
            ></el-table-column>

            <el-table-column
              label="所属商户"
              prop="merchantName"
            ></el-table-column>

            <el-table-column
              label="添加时间"
              prop="createTime"
            ></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <el-link
                    type="primary"
                    :underline="false"
                    @click="openDialog('edit', scope.row)"
                  >
                    <i class="el-icon-edit-outline"></i>
                    编辑
                  </el-link>
                  <span
                    class="ml-10 tip-text-delete"
                    @click="deleteMerchantHandle(scope.row.id)"
                  >
                    <i class="el-icon-delete"></i>
                    删除
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            :total="total"
            :pageNum="pageNum"
            :pageSize="pageSize"
            :pageSizes="pageSizes"
            @changePage="search"
            @syncPageData="syncPageData"
          />
        </div>
      </el-col>
    </el-row>

    <!-- 编辑、新增核销商户弹窗 -->
    <el-dialog
      :visible.sync="showDialog"
      :title="opeType == 'add' ? '添加核销人员' : '编辑核销人员'"
      @close="clearDialog"
    >
      <div class="pad-container">
        <el-form
          class="mt-24"
          label-width="120px"
          :model="form"
          :rules="rules"
          ref="form"
        >
          <el-form-item label="核销人员姓名" prop="name">
            <el-input
              class="w-100"
              placeholder="请输入核销人员姓名"
              v-model="form.name"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input
              class="w-100"
              placeholder="请输入手机号"
              clearable
              v-model="form.phone"
              :maxlength="11"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="passwordAdd" v-if="opeType == 'add'">
            <el-input
              class="w-100"
              placeholder="请输入密码"
              clearable
              v-model="form.passwordAdd"
              type="password"
              :max-length="30"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="passwordModify" v-if="opeType == 'edit'">
          <el-input
                  class="w-100"
                  placeholder="请输入密码"
                  clearable
                  v-model="form.passwordModify"
                  type="password"
                  :max-length="30"
          ></el-input>
        </el-form-item>
          <el-form-item label="核销人员类型" prop="type">
            <el-radio-group v-model="form.type">
              <el-radio :label="1">商户核销人员</el-radio>
              <el-radio :label="2">门店核销人员</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="所属门店" prop="storeId" v-if="form.type">
            <el-select
              placeholder="请选择门店"
              class="w-100"
              v-model="form.storeId"
              @change="getMerchantsListHandle"
            >
              <el-option
                v-for="(item, index) in storeList"
                :key="index"
                :label="item.storeName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="所属商户"
            prop="merchantId"
            v-if="form.type == 1 && form.storeId"
          >
            <el-select
              v-model="form.merchantId"
              filterable
              placeholder="请选择商户"
              class="w-100"
            >
              <el-option
                v-for="item in merchantList"
                :key="item.merchantId"
                :label="item.merchantName"
                :value="Number(item.merchantId) "
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="flx-row ali-c js-fe mt-50">
          <el-button
            plain
            size="small"
            class="form-button-w70"
            @click="showDialog = false"
            >取消</el-button
          >
          <el-button
            class="form-button-w70"
            size="small"
            type="primary"
            @click="submit"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  getStoreList,
  getMerchantsListByStoreId,
} from "@/api/online_department_store/commodity_manage";
import {
  deleteMerchant,
  getCheckMerchantList,
  getCheckMerchantDetail,
  addMerchantAccount,
  updateMerchantAccount,
} from "@/api/verify_center/verify_merchants";
export default {
  name: "verify_merchants_list",
  components: {
    Pagination,
  },
  data() {
    return {
      // 顶部搜索栏数据
      searchObj: {
        merchantName: "",
        name: "",
        // storeId: "",
        phone: "",
      },
      showDialog: false,
      opeType: "",
      storeList: [],
      merchantList: [],
      form: {
        name: "",
        phone: "",
        passwordAdd: "",
        passwordModify: "",
        type: "",
        storeId: "",
        merchantId: "",
        authority: [],
      },
      rules: {
        name: [
          { required: true, message: "核销人员姓名不能为空", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          {
            pattern: /^[1]([3-9])[0-9]{9}$/,
            message: "号码格式有误",
            trigger: "blur",
          },
        ],
        passwordAdd: [
          { required: true, message: "密码不能为空", trigger: "blur" }
        ],
        type: [
          { required: true, message: "核销人员类型不能为空", trigger: "blur" },
        ],
        storeId: [{ required: true, message: "门店不能为空", trigger: "blur" }],
        merchantId: [
          { required: true, message: "商户不能为空", trigger: "blur" },
        ],
        authority: [
          { required: false, message: "权限不能为空", trigger: "blur" },
        ],
      },
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      selectedRow: {},
    };
  },
  mounted() {
    this.initData();
    this.getStores();
  },
  methods: {
    initData(searchData = {}) {
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      data = Object.assign(data, searchData);
      getCheckMerchantList(data).then((res) => {
        this.tableData = res.data.body.list;
        this.total = res.data.body.total;
      });
    },
    //获取门店列表
    getStores() {
      getStoreList().then((res) => {
        this.storeList = res && res.data.body.list;
      });
    },
    //获取商户列表
    getMerchantsListHandle() {
      //获取商户列表
      if (this.form.type == 1) {
        //商户核销人员
        getMerchantsListByStoreId({ shopId: this.form.storeId }).then((res) => {
          // console.log(res.data.body)
          if (res.data.body) {
            this.merchantList = res.data.body;
          }
        });
      }
    },
    // 搜索事件
    search() {
      let data = this.searchObj;
      this.initData(data);
    },
    // 重置搜索关键词
    reset() {
      this.searchObj = {
        merchantName: "",
        name: "",
        // storeId: "",
        phone: "",
      };
      this.initData();
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
    // 会员多选事件
    tableSelect(e) {
      // // console.log(e);
      this.multiSeletction = e;
    },
    // 优惠券多选事件
    selectCou(e) {
      this.selectedCou = e;
    },
    openDialog(type, row) {
      //打开核销商户编辑、新增弹窗
      this.opeType = type;
      // console.log("row", row);
      this.selectedRow = row;
      if (type == "edit") {
        getCheckMerchantDetail(row.id).then((res) => {
          // console.log(res.data.body);
          this.form = res.data.body;
          if (res.data.body.storeId && res.data.body.type == 1) {
            getMerchantsListByStoreId({ shopId: res.data.body.storeId }).then(
              (res) => {
                if (res.data.body) {
                  // console.log(res.data.body)
                  this.merchantList = res.data.body;
                }
              }
            );
          }
        });
      } else {
        //添加
        this.form.storeId = "";
        this.form.merchantId = "";
      }
      this.showDialog = true;
    },
    clearDialog() {
      //关闭核销商户编辑、新增弹窗
      this.form = {
        name: "",
        phone: "",
        passwordAdd: "",
        passwordModify: "",
        type: "",
        storeId: "",
        merchantId: "",
        authority: [],
      };
    },
    submit() {
      //提交核销商户验证
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          this.$message({
            message: "表单信息不完整（有误）",
            type: "warning",
          });
          return;
        }
        this.submitForm();
      });
    },
    submitForm() {
      //提交核销商户
      // console.log(this.form);
      let params = {
        name: this.form.name,
        phone: this.form.phone,
        password: this.opeType == "add" ? this.form.passwordAdd : this.form.passwordModify,
        type: this.form.type,
        merchantId: this.form.merchantId,
        storeId: this.form.storeId,
      };
      if (this.opeType == "add") {
        addMerchantAccount(params).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          }
          this.showDialog = false;
          this.initData();
        });
      } else if (this.opeType == "edit") {
        params.id = this.selectedRow.id;
        updateMerchantAccount(params).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          }
          this.showDialog = false;
          this.initData();
        });
      }
    },
    deleteMerchantHandle(id) {
      this.$confirm("此操作将永久删除该商户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          deleteMerchant(id).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.initData();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
</style>
