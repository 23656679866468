import request from "@/utils/request";

//删除核销商户账号
//地址：http://${BASE_URL}/customer-service/merchantAccount/delMerchantAccount?id={id}
export function deleteMerchant(id) {
  return request({
    url: `/customer-service/merchantAccount/delMerchantAccount?id=${id}`,
    method: 'delete',
  });
}

//获取核销商户列表
export function getCheckMerchantList(data) {
    return request({
      url: '/customer-service/merchantAccount/queryMerchantAccountInfo',
      method: 'post',
      data
    });
}

//根据Id获取核销商户详情
export function getCheckMerchantDetail(id) {
    return request({
      url: `/customer-service/merchantAccount/queryMerchantAccountDetails?id=${id}`,
      method: 'get'
    });
}


//接口：添加商户账号
//地址：http://${BASE_URL}/customer-service/merchantAccount/addMerchantAccount
export function addMerchantAccount(data) { 
    return request({
      url: '/customer-service/merchantAccount/addMerchantAccount',
      method: 'post',
      data
    });
}

//接口：核销商户账号修改
//地址：http://${BASE_URL}/customer-service/merchantAccount/updateMerchantAccount
export function updateMerchantAccount(data) { 
  return request({
    url: '/customer-service/merchantAccount/updateMerchantAccount',
    method: 'post',
    data
  });
}
